<template>
  <div>
    <!-- Membership  11111 -->
    <!-- 状态判断 -->
    <div class="floor1">
      <div v-if="!infoList.id && isFormFir">
        <div class="row pb-5">
          <div class="col-sm-3 col-3 titleColor">
            {{ $t("Set.440@user_type") }}
          </div>
          <div class="col-sm-9 col-9">{{ $t("Set.10c@guest") }}</div>
        </div>
        <div class="mt-5 mb-3">
          <p>{{ $t("Set.e0a@select_an_account") }}</p>
          <select class="form-control applyType" v-model="type">
            <option value="0">{{ $t("Set.3ce@member") }}</option>
            <!-- <option value="1">{{ $t("Set.ab4@alumni") }}</option> -->
            <option value="2">{{ $t("Set.a6d@probationary_member") }}</option>
            <!-- <option value="2">Partner</option>
            <option value="3">Sponsor</option> -->
          </select>
          <button type="submit" class="btn btn1" @click="become">
            {{ $t("Set.ca8@submit") }}
          </button>
        </div>
      </div>
      <!-- Membership 222 -->
      <div class="floor2" v-if="!isFormFir">
        <div>
          <div class="form-group">
            <label>{{ $t("Set.018@national_organizatio") }}</label>
            <el-select
              class="w-100"
              v-model="organization"
              filterable
              value-key="id"
              @change="
                getLocalList(organization, 3);
                getLocalList(organization, 1);
              "
            >
              <el-option
                v-for="v in organizationList"
                :key="v.id"
                :label="v.name"
                :value="v"
              >
              </el-option>
            </el-select>
          </div>
          <div class="form-group">
            <label>{{ $t("Set.bb4@regional_organizatio") }}</label>
            <el-select
              class="w-100"
              v-show="regionalList.length > 0"
              filterable
              v-model="region"
              value-key="id"
              @change="getLocalList(region, 1)"
            >
              <el-option
                v-for="v in regionalList"
                :label="v.name"
                :key="v.id"
                :value="v"
              >
              </el-option>
            </el-select>
            <el-select
              class="w-100"
              v-model="region"
              v-show="regionalList.length < 1"
              disabled
            >
              <el-option value="">{{
                $t("Set.c78@there_are_no_regiona")
              }}</el-option>
            </el-select>
          </div>
          <div class="form-group">
            <label>{{ $t("Set.e5a@local_organization") }}</label>
            <el-select
              class="w-100"
              filterable
              v-show="localList.length > 0"
              v-model="local"
            >
              <el-option
                v-for="v in localList"
                :label="v.name"
                :key="v.id"
                :value="v.id"
              >
              </el-option>
            </el-select>
            <el-select
              class="w-100"
              v-model="local"
              v-show="localList.length < 1"
              disabled
            >
              <el-option value="">{{
                $t("Set.bc5@there_are_no_local")
              }}</el-option>
            </el-select>
          </div>
        </div>
        <!-- 重新提交 -->
        <div class="btns mt-5 mb-3" v-if="infoList.id">
          <button type="submit" class="btn btn-primary mr-2" @click="editVip()">
            {{ $t("Set.ca8@submit") }}
          </button>
        </div>
        <!-- 申请 -->
        <div class="btns mt-5 mb-3" v-else>
          <button
            type="submit"
            class="btn btn-primary mr-2"
            @click="applyVip()"
          >
            {{ $t("Set.ca8@submit") }}
          </button>
          <button type="submit" class="btn btn-danger" @click="back">
            {{ $t("Set.be6@back") }}
          </button>
        </div>
      </div>
    </div>

    <!-- Membership  444 -->
    <div class="floor4" v-if="infoList.id && isShowInfo">
      <div class="p-2">
        <div class="row">
          <!-- User Type -->
          <div class="col-sm-4 col-6 titleColor">
            {{ infoList.status!=3?"Application":" "}}
            Type
          </div>
          <div class="col-sm-8 col-6" v-if="infoList.type == 0">
            {{ $t("Set.3ce@member") }}
          </div>
          <!-- <div class="col-sm-8" v-else-if="infoList.type == 1">
              {{ $t("Set.ab4@alumni") }}
            </div> -->
          <div class="col-sm-8 col-6" v-else-if="infoList.type == 2">
            {{ $t("Set.a6d@probationary_member") }}
          </div>
        </div>
        <div class="row my-2">
          <!-- Chapter -->
          <div class="col-sm-4 col-6 titleColor">
            Organization
          </div>
          <div class="col-sm-8 col-62">
            {{ infoList.organization }}
          </div>
        </div>
        <div class="row my-2" v-if="infoList.status == 1">
          <!-- 会费 -->
          <div class="col-sm-4 col-6 titleColor">
            {{ $t("Set.d31@membership_fee") }}
          </div>
          <div class="col-sm-8 col-6 titleColor">
            ${{ infoList.member_fee }}
          </div>
        </div>
        <div class="row my-2" v-if="infoList.status != 3">
          <div class="col-sm-4 col-6 titleColor">
            Status
          </div>
          <div class="col-sm-8 col-6">
            {{
              infoList.status == 0
                ? "Pending"
                : infoList.status == 1
                ? "Payment after approval"
                : infoList.status == 2
                ? "Refuse"
                : "Become a member"
            }}
          </div>
        </div>
        <div class="optionBtn">
          <div class="floor3" v-if="infoList.status == 0">
            <div class="mt-5 mb-3">
              <el-button type="info" plain disabled>{{
                $t("Set.2b3@under_review")
              }}</el-button>
              <a
                href="javascript:;"
                style="color: #ff9b8a; text-decoration: underline; margin-left: 15px"
                @click="delVip"
                >{{ $t("Set.cf1@withdraw_the_appli") }}</a
              >
            </div>
          </div>
          <div class="my-3" v-if="infoList.status == 1">
            <div class="word-wrap: break-word">
              {{ $t("Set.c3d@the_membership_you") }}
            </div>
            <button
              id="payButtonId"
              @click="handlePay"
              class="btn btn-primary mt-2"
            >
              {{ $t("Set.f25@pay") }}
            </button>
          </div>
          <div v-if="infoList.status == 2">
            <div>
              <p
                class="p-2 mt-3"
                style="color: #ffaea1; background: #fff0f0;border-radius:5px"
              >
                Your membership application was declined. The reason is
                {{ infoList.body }}
              </p>
            </div>
            <div class="mt-5 mb-3">
              <button type="submit" class="btn btn1" @click="changeVip">
                Re-apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      type: "0",
      organizationList: [],
      regionalList: [],
      localList: [],
      organization: {},
      region: {},
      local: "",
      national: 0,
      infoList: {},
      isShowBtn: false,
      applyId: "",
      isFormFir: true,
      isShowInfo: false
    };
  },
  props: ["userList"],
  computed: {
    ...mapGetters({
      hppResponse: "Public/getHppResponse",
      payStatus: "Public/getPayStatus"
    })
  },
  watch: {
    hppResponse: {
      deep: true,
      handler: function(newData, oldData) {
        this.$http.vipPayResponse({ hppResponse: newData }).then(res => {
          if (res.status == 200) {
            // this.$store.commit('Public/CHANGE_PAY_STATUS', true)
            this.isShowBtn = true;
            this.$message.success(res.message);
          }
        });
      }
    }
  },
  methods: {
    async handlePay() {
      // this.$router.push({
      //   path: '/PayMember',
      //   query: { type: 5, id: applyId },
      // })
      let res = await this.$http.vipCreateOrder({
        apply_id: this.applyId,
        time_zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone
      });
      if (res.status == 200) {
        if (res.data.is_jump == 1) {
          this.$router.push({
            path: "/PayMoney",
            query: { model_type: 5, id: res.data.id }
          });
        } else {
          this.$router.push({
            path: "/PaySuccess",
            query: { model_type: 5 }
          });
        }
      }
    },

    clickEvent() {
      this.isFormFir = true;
      this.applyVipStatus();
      this.getOrganizationList();
      this.getorg();
    },
    async getorg() {
      this.organization = this.userList.organization.national;
      this.organization.parent_id = undefined;
      this.region = this.userList.organization.regional;
      if (
        this.userList.organization.regional &&
        this.userList.organization.organization.id
      ) {
        await this.getLocalList(this.organization, 3);
        await this.getLocalList(this.region, 1);
        this.organization.has_regions = 1;
      } else {
        await this.getLocalList(this.organization, 1);
        this.organization.has_regions = 0;
      }
      this.local = this.userList.organization.organization.id;
    },
    delVip() {
      this.$http.delVip({ id: this.applyId }).then(res => {
        if (res.status == 200) {
          this.applyVipStatus();
        }
      });
    },
    changeVip() {
      this.isFormFir = false;
      this.isShowInfo = false;
    },
    editVip() {
      if (this.local) {
        this.$http
          .editVip({
            id: this.applyId,
            organization_id: this.local
          })
          .then(res => {
            if (res.status == 200) {
              this.isFormFir = true;
              this.applyVipStatus();
            }
          });
      } else {
        this.$message("Please select local level");
      }
    },
    back() {
      if (this.infoList.id) {
        this.isFormFir = true;
        this.isShowInfo = true;
      } else {
        this.isFormFir = true;
      }
    },
    become() {
      this.isFormFir = false;
    },
    getOrganizationList() {
      this.organizationList = [];
      this.$http.getOrganizationList({ type: 2 }).then(res => {
        if (res.status == 200) {
          // this.organizationList = res.data;
          res.data.forEach((item, index) => {
            return this.organizationList.push({
              name: item.name,
              id: item.id,
              parent_id: item.parent_id,
              has_regions: item.has_regions
            });
          });
        }
      });
    },
    async getLocalList(parent, type) {
      if (type == 3) {
        let res = await this.$http.getOrganizationList({
          parent_id: parent.id,
          type
        });
        if (res.status == 200) {
          if (type == 3) {
            this.regionalList = [];
            // this.regionalList = res.data;
            res.data.forEach((item, index) => {
              return this.regionalList.push({
                name: item.name,
                id: item.id,
                parent_id: parent.id
              });
            });
            // console.log("this.regionalList",this.regionalList);
          }
        }
      } else {
        this.local = "";
        if (parent) {
          let res = await this.$http.getOrganizationList({
            parent_id: parent.id,
            type
          });
          if (res.status == 200) {
            this.localList = [];
            res.data.forEach((item, index) => {
              return this.localList.push({
                name: item.name,
                id: item.id
              });
            });
            // console.log("this.localList",this.localList)
          }
        }
      }
    },
    // 查询会员状态
    applyVipStatus() {
      this.$http.applyVipStatus().then(res => {
        if (res.status == 200) {
          this.infoList = res.data;
          this.applyId = res.data.id;
          if (this.infoList.id) {
            this.isShowInfo = true;
          }
        }
      });
    },
    // 申请成为会员
    applyVip() {
      if (this.local) {
        this.$http
          .applyVip({
            type: this.type,
            // nation_id: this.national,
            organization_id: this.local
          })
          .then(res => {
            if (res.status == 200) {
              this.applyVipStatus();
              this.isFormFir = true;
              this.organization = {};
              this.region = "";
              this.local = "";
              this.national = "";
            }
          });
      } else {
        this.$message("Please select local level");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.btn1 {
  color: #fff;
  background-color: #50b5ff;
  border: 3px solid #50b5ff;
}

::v-deep .el-input__inner {
  height: 45px !important;
  line-height: 45px;
  background: transparent;
  border: 1px solid #ced4da;
  font-size: 14px;
  color: var(--iq-secondary);
  border-radius: 10px;
}

.applyType {
  width: 130px !important;
  display: inline-block;
  height: 39px !important;
  border-radius: 5px !important;
  margin-right: 10px;
}
</style>
